import React from "react"
import Title from "./title"

import image from "src/images/otodokemon_system.png";

const PALE_BLUE = "#E6F8FA"
const LETTER_SPACING_SIZE = "0.1em";

const DesktopSpacer = () => <div className="is-hidden-mobile" style={{ width: "1rem", height: "3rem" }} />

const System = () => {
  return (
    <section className="section is-medium" style={{ backgroundColor: PALE_BLUE }}>
      <div className="container">
        <Title isForPaleBgd>
          <span className="is-inline-block" >おトドけもんの</span>
          <span className="is-inline-block" >中身のヒミツ</span>
        </Title>

        <p className="has-text-weight-bold is-size-7-mobile mb-6" style={{ lineHeight: "2em", letterSpacing: LETTER_SPACING_SIZE }}>
          おトドけもんに搭載されている拡張キットは、プライベートなモバイルアプリを展開できるサービスnatadeCOCOをソフトウェア基盤としているため、ロボットの可動域を制限しないポータブルな構成でありながら、運用中の個別バージョンアップに対応しています。
        </p>

        <img src={image} alt="おトドけもんのシステム" style={{ borderRadius: "1rem" }} />
        <DesktopSpacer />

        <div className="box is-shadowless has-background-white mt-5" style={{ borderRadius: "10rem" }}>
          <a href="https://natade-coco.com" target="_blank" rel="noopener noreferrer">
            <p className="is-size-7-mobile has-text-weight-bold has-text-centered" style={{ letterSpacing: LETTER_SPACING_SIZE }}>natadeCOCOについてはこちら</p>
          </a>
        </div>
      </div>
    </section>
  )
}

export default System