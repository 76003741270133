import React from "react"

import logImage from "src/images/logo_rgb_yoko_white.svg"
import otodokemon from "src/images/otodokemon_upper_body.png"

const DARK_BLUE = "#144895";
const LETTER_SPACING_SIZE = "0.05rem";

const TWITTER_URL = "https://twitter.com/nCOCOSupport"

const Logo = () => {
  const ALT = "natadeCOCOロゴ"
  const MOBILE_SIZE = "200px"
  const DESKTOP_SIZE = "360px"
  const ForMobile = () => <img className="is-hidden-tablet" src={logImage} alt={ALT} style={{ width: MOBILE_SIZE }} />;
  const ForDesktop = () => <img className="is-hidden-mobile" src={logImage} alt={ALT} style={{ width: DESKTOP_SIZE }} />;
  return (
    <>
      <ForMobile />
      <ForDesktop />
    </>
  );
}

const TwitterButton = () => (
  <span className="icon is-large my-auto">
    <a className="has-text-white" href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
      <i className="fab fa-2x fa-twitter" />
    </a>
  </span>
)

const Footer = () => {
  return (
    <footer className="footer has-text-white pb-5" style={{ backgroundColor: DARK_BLUE, overflow: "hidden" }}>
      <div className="container is-relative">
        <div className="is-flex is-justify-content-space-between is-align-content-center">
          <Logo />
          <TwitterButton />
        </div>

        <div className="content my-6 py-4">
          <p className="has-text-weight-bold is-size-7-mobile" style={{ letterSpacing: LETTER_SPACING_SIZE }}>
            「QRコード®」は、株式会社デンソーウェーブの商標または登録商標です。
          </p>
          <p className="has-text-weight-bold is-size-7-mobile" style={{ letterSpacing: LETTER_SPACING_SIZE }}>
            natadeCOCO、natadeCOCO のロゴは、株式会社スーパーソフトウエアの商標または登録商標です。
          </p>
        </div>


        <p className="has-text-weight-bold is-size-7-mobile has-text-centered" style={{ letterSpacing: LETTER_SPACING_SIZE }}>
          © 2021 SuperSoftware Co., Ltd.
        </p>

        <img src={otodokemon} alt="おトドけもん" style={{ width: "clamp(50px, 10vw, 87px)", position: "absolute", bottom: "-1.5rem", right: "0" }} />
      </div>
    </footer>
  )
}

export default Footer