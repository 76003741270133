import React from "react"
import Title from "./title"

import post1 from "src/images/otodokemon_howto_post_1.png"
import post2 from "src/images/otodokemon_howto_post_2.png"
import post3 from "src/images/otodokemon_howto_post_3.png"
import post4 from "src/images/otodokemon_howto_post_4.png"
import receive1 from "src/images/otodokemon_howto_receive_1.png"
import receive2 from "src/images/otodokemon_howto_receive_2.png"
import receive3 from "src/images/otodokemon_howto_receive_3.png"
import receive4 from "src/images/otodokemon_howto_receive_4.png"

const PALE_BLUE = "#E6F8FA"
const LETTER_SPACING_SIZE = "0.02em";

const SubTitle = ({ children }) => (
  <div className="px-6 py-2 mx-auto has-background-primary" style={{ borderRadius: "10rem", width: "fit-content" }}>
    <p className="is-size-4 is-size-5-touch has-text-weight-bold has-text-white" style={{ letterSpacing: "0.1em" }}>
      {children}
    </p>
  </div>
)

const Image = ({ src }) => (
  <>
    <img className="is-hidden-tablet mr-4" src={src} alt="使い方" style={{ width: "clamp(100px, 40%, 200px)" }} />
    <img className="is-hidden-mobile" src={src} alt="使い方" />
  </>
)

const ColumnsContaier = ({ children }) => {
  const MobileTriangle = ({ topPosition }) => <div className="is-hidden-tablet" style={{ position: "absolute", width: "1rem", height: "2rem", top: topPosition, left: "calc(50% - 0.5rem)", border: "solid 1rem transparent", borderTopColor: "#fff", zIndex: "-1" }} />
  const TabletTriangle = ({ leftPosition }) => <div className="is-hidden-mobile" style={{ position: "absolute", width: "1rem", height: "2rem", top: "calc(50% - 1rem)", left: leftPosition, border: "solid 1rem transparent", borderLeftColor: "#fff", zIndex: "-1" }} />

  return (
    <div className="columns my-6 is-relative is-variable is-4" style={{ zIndex: "1" }}>
      {children}
      <MobileTriangle topPosition="calc(25% - 0.5rem)" />
      <MobileTriangle topPosition="calc(50% - 0.5rem)" />
      <MobileTriangle topPosition="calc(75% - 0.5rem)" />
      <TabletTriangle leftPosition="calc(25% - 0.5rem)" />
      <TabletTriangle leftPosition="calc(50% - 0.5rem)" />
      <TabletTriangle leftPosition="calc(75% - 0.5rem)" />
    </div>
  )
}

const Item = ({ children, imageSrc }) => (
  <div className="column">
    <div className="box is-shadowless p-0" style={{ borderRadius: "1rem", height: "100%", overflow: "hidden" }}>
      <div className="is-flex-mobile is-align-items-center">
        <Image src={imageSrc} />
        <p className="is-size-7-touch has-text-weight-bold has-text-centered-tablet my-4 mx-2" style={{ letterSpacing: LETTER_SPACING_SIZE }}>{children}</p>
      </div>
    </div>
  </div>
)

const Howto = () => {
  return (
    <section className="section is-medium pb-6" style={{ backgroundColor: PALE_BLUE }}>
      <div className="container">
        <Title isForPaleBgd>
          <span className="is-inline-block" >おトドけもんの</span>
          <span className="is-inline-block" >使い方</span>
        </Title>

        <SubTitle>荷物を送る側</SubTitle>
        <ColumnsContaier>
          <Item imageSrc={post1}>荷物を乗せて、<br />QRコードからアプリを起動</Item>
          <Item imageSrc={post2}>AIカメラが荷物を判別します</Item>
          <Item imageSrc={post3}>宛先が自動で設定されます</Item>
          <Item imageSrc={post4}>お届けに出発♪</Item>
        </ColumnsContaier>

        <div style={{ width: "1rem", height: "4rem" }} />

        <SubTitle>荷物を受け取る側</SubTitle>
        <ColumnsContaier>
          <Item imageSrc={receive1}>到着したおトドけもんの<br />QRコードからアプリを起動</Item>
          <Item imageSrc={receive2}>荷物情報と送り元が<br />確認できます</Item>
          <Item imageSrc={receive3}>荷物を下ろして、<br />受取り完了をタップ</Item>
          <Item imageSrc={receive4}>元の場所に帰ります♪</Item>
        </ColumnsContaier>

        <div style={{ width: "1rem", height: "2rem" }} />
      </div>
    </section>
  )
}

export default Howto