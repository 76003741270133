import React from "react"
import logo from "src/images/otodokemon_kaiyo_logo.png"

const LETTER_SPACING_SIZE = "0.05rem";

const KAIYO_WEBSITE_URL = "https://kaiyo-robotics.com/"

const Logo = () => {
  const ALT = "海容株式会社のロゴ"
  const MOBILE_SIZE = "100%";
  const DESKTOP_SIZE = "60%";
  const ForMobile = () => <img className="is-hidden-tablet" src={logo} alt={ALT} style={{ width: MOBILE_SIZE, height: "auto", objectFit: "cover" }} />;
  const ForDesktop = () => <img className="is-hidden-mobile" src={logo} alt={ALT} style={{ width: DESKTOP_SIZE, height: "auto", objectFit: "cover" }} />;
  return (
    <>
      <ForMobile />
      <ForDesktop />
    </>
  );
}

const Developper = () => {
  const MAX_WIDTH = "980px"

  return (
    <section className="section has-background-white">
      <div className="container">
        <div className="columns is-vcentered is-mobile mx-auto" style={{ maxWidth: MAX_WIDTH }}>
          <div className="column">
            <p className="has-text-weight-bold is-size-7-mobile mb-0" style={{ letterSpacing: LETTER_SPACING_SIZE }}>
              「おトドけもん」は、各種PUDU社製ロボットを取り扱う<a href={KAIYO_WEBSITE_URL} target="_blank" rel="noopener noreferrer">海容株式会社</a>との共同ソリューションです。
            </p>
          </div>
          <div className="column is-2 ml-4" >
            <Logo />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Developper