import React from "react"
import Styles from "./title.module.scss"

import NikuQ from "-!svg-react-loader!src/images/otodokemon_nikuq.svg"

const FONT_WHITE = "#fff";
const FONT_DARK = "#2a2a2a";

const MOBILE_CLASS = "is-hidden-tablet"
const TABLET_CLASS = "is-hidden-desktop is-hidden-mobile"
const DESKTOP_CLASS = "is-hidden-touch"

const BgdImage = () => {
  const COMMON_STYLE = { position: "absolute", zIndex: "-1" }

  return (
    <>
      <div className={`${MOBILE_CLASS} ${Styles.nikuQ}`} style={{ ...COMMON_STYLE, width: "160px", top: "-35px", left: "-35px" }}>
        <NikuQ />
      </div>
      <div className={`${TABLET_CLASS} ${Styles.nikuQ}`} style={{ ...COMMON_STYLE, width: "180px", top: "calc(-45px + 2rem)", left: "-45px" }}>
        <NikuQ />
      </div>
      <div className={`${DESKTOP_CLASS} ${Styles.nikuQ}`} style={{ ...COMMON_STYLE, width: "240px", top: "-75px", left: "-75px" }}>
        <NikuQ />
      </div>
    </>
  )
}

const Title = ({ children, isLeft = false, isForPaleBgd = false }) => {
  const position = isLeft ? "ml-0 mr-auto" : "mx-auto";
  const fontColorStyle = isForPaleBgd ? { color: FONT_DARK } : { color: FONT_WHITE };

  const body = (
    <>
      <div className={TABLET_CLASS} style={{ width: "1rem", height: "2rem" }} />
      <h2 className={`title is-size-4-mobile is-size-3 is-size-2-desktop mb-4 has-text-centered ${isLeft && "has-text-left-tablet"}`} style={{ letterSpacing: "0.1em", ...fontColorStyle }}>{children}</h2>
      <div className="is-hidden-touch" style={{ width: "1rem", height: "2rem" }} />
      <BgdImage />
    </>
  )

  return (
    <>
      <div className={`is-hidden-tablet p-6 mx-auto mb-4 is-relative`} style={{ width: "fit-content", zIndex: "0" }}>
        {body}
      </div>
      <div className={`is-hidden-mobile p-6 ${position} mb-4 is-relative`} style={{ width: "fit-content", zIndex: "0" }}>
        {body}
      </div>
    </>
  )
}

export default Title