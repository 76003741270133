import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Hero from "src/components/otodokemon/hero"
import Concept from "src/components/otodokemon/concept"
import Howto from "src/components/otodokemon/howto"
import Feature from "src/components/otodokemon/feature"
import System from "src/components/otodokemon/system"
import Scene from "src/components/otodokemon/scene"
import Contact from "src/components/otodokemon/contact"
import Event from "src/components/otodokemon/event"
import Developper from "src/components/otodokemon/developper"
import Footer from "src/components/otodokemon/footer"

const IndexPage = () => (
  <Layout>
    <Seo
      title="おトドけもん | natadeCOCOが贈る運搬DXソリューション"
      ogpImage="https://natade-coco.gitlab.io/assets/otodokemon_ogp.png"
    />
    <Hero />
    <Concept />
    <Howto />
    <Feature />
    <System />
    <Scene />
    <Contact />
    <Event />
    <Developper />
    <Footer />
  </Layout>
)

export default IndexPage
