import React from "react"

import logImage from "src/images/logo_rgb_yoko_color.svg"
import otodokemonLogo from "src/images/otodokemon_logo.svg"
import heroImage from "src/images/otodokemon_hero_image.png"
import bgdImage from "src/images/otodokemon_hero_bgd.png"
import patternImage from "src/images/top_pattern_2.svg"

const DARK_BLUE = "#144895";

const MOBILE_CLASS = "is-hidden-tablet"
const TABLET_CLASS = "is-hidden-desktop is-hidden-mobile"
const DESKTOP_CLASS = "is-hidden-touch is-hidden-widescreen"
const WIDESCREEN_CLASS = "is-hidden-touch is-hidden-desktop-only is-hidden-fullhd"
const FULLHD_CLASS = "is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only"

const Logo = () => {
  const ALT = "natadeCOCOロゴ"
  const MOBILE_SIZE = "200px"
  const DESKTOP_SIZE = "300px"
  const ForMobile = () => <img className="is-hidden-tablet" src={logImage} alt={ALT} style={{ width: MOBILE_SIZE }} />;
  const ForDesktop = () => <img className="is-hidden-mobile" src={logImage} alt={ALT} style={{ width: DESKTOP_SIZE }} />;
  return (
    <>
      <ForMobile />
      <ForDesktop />
    </>
  );
}

const BodyContainer = ({ children }) => {
  const COMMON_CLASS = ""
  const COMMON_STYLES = { backgroundImage: `url(${heroImage})`, backgroundSize: "cover", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }

  const Pattern = ({ addStyle }) => <img className="is-hidden-mobile" src={patternImage} alt="背景" style={{ opacity: "0.5", position: "absolute", ...addStyle }} />

  return (
    <>
      {/* for Mobile */}
      <div className={`${COMMON_CLASS} ${MOBILE_CLASS}`} style={{
        backgroundImage: `url(${heroImage}), url(${bgdImage})`, backgroundSize: "clamp(200px, 330vw, 1500px) auto, clamp(200px, 330vw, 1500px) auto", backgroundPosition: "right -100px bottom, right -100px top", backgroundRepeat: "no-repeat, repeat-y",
        padding: "5rem 2rem 1rem"
      }}>
        {children}
      </div>
      {/* for Tablet */}
      <div className={`${COMMON_CLASS} ${TABLET_CLASS}`} style={{ ...COMMON_STYLES, padding: "8rem 2rem 5rem", backgroundSize: "1700px auto", backgroundPosition: "right -300px bottom" }}>
        {children}
      </div>
      {/* for Desktop */}
      <div className={`${COMMON_CLASS} ${DESKTOP_CLASS}`} style={{ ...COMMON_STYLES, padding: "8rem 2rem", backgroundSize: "1700px auto", backgroundPosition: "right -200px bottom" }}>
        {children}
      </div>
      {/* for Widescreen */}
      <div className={`${COMMON_CLASS} ${WIDESCREEN_CLASS}`} style={{ ...COMMON_STYLES, padding: "8rem 2rem", backgroundSize: "1700px auto", backgroundPosition: "right -100px bottom" }}>
        {children}
      </div>
      {/* for FullHD */}
      <div className={`${COMMON_CLASS} ${FULLHD_CLASS} mx-auto`} style={{ ...COMMON_STYLES, padding: "8rem 2rem", backgroundSize: "2000px auto", maxWidth: "1800px" }}>
        {children}
      </div>
      {/* for Bgd */}
      <Pattern addStyle={{ top: "-50px", left: "60%" }} />
      <Pattern addStyle={{ bottom: "-25px", left: "-70px", transform: "rotate(225deg)" }} />
    </>
  )
}

const Copy = ({ children }) => {
  const Container = ({ children, addClass = "", addStyle = {} }) => (
    <div className={addClass} style={{ backgroundColor: DARK_BLUE, borderRadius: "10rem", width: "fit-content", ...addStyle }}>
      {children}
    </div>
  )

  const Text = ({ children, addClass = "", addStyle = {} }) => (
    <p className={`subtitle is-size-6-mobile is-size-5 is-size-4-desktop has-text-weight-bold has-text-white has-text-centered ${addClass}`} style={{ letterSpacing: "0.1em", ...addStyle }}>
      {children}
    </p>
  )

  return (
    <>
      {/* for Mobile */}
      <Container addClass={`${MOBILE_CLASS} px-5 py-3 mx-auto`} addStyle={{ marginBottom: "16rem" }}>
        <Text addStyle={{ lineHeight: "1.5em" }}>{children}</Text>
      </Container>
      {/* for Tablet */}
      <Container addClass={`${TABLET_CLASS} px-6 py-4`}>
        <Text >{children}</Text>
      </Container>
      {/* for Desktop */}
      <Container addClass={`${DESKTOP_CLASS} px-6 py-4`}>
        <Text >{children}</Text>
      </Container>
      {/* for Widescreen */}
      <Container addClass={`${WIDESCREEN_CLASS} px-6 py-4`}>
        <Text >{children}</Text>
      </Container >
      {/* for FullHD */}
      <Container addClass={`${FULLHD_CLASS} px-6 py-4`}>
        <Text >{children}</Text>
      </Container >
    </>
  )
}

const ServiceLogo = () => {
  const Image = ({ addClass = "", addStyle = {} }) => <img className={addClass} src={otodokemonLogo} alt="おトドけもん" style={{ ...addStyle }} />

  return (
    <>
      {/* for Mobile */}
      <div className={`${MOBILE_CLASS} my-3 mx-auto`} style={{ width: "100%", maxWidth: "500px" }} >
        <Image />
      </div>
      {/* for Tablet */}
      <Image addClass={TABLET_CLASS} addStyle={{ width: `clamp(520px, 65vw, 650px)`, margin: "75px 0 50px" }} />
      {/* for Desktop */}
      <Image addClass={DESKTOP_CLASS} addStyle={{ width: `clamp(520px, 60vw, 750px)`, margin: "90px 0 50px" }} />
      {/* for Widescreen */}
      <Image addClass={WIDESCREEN_CLASS} addStyle={{ width: "750px", margin: "100px 0 75px" }} />
      {/* for FullHD */}
      <Image addClass={FULLHD_CLASS} addStyle={{ width: ` clamp(750px, 50vw, 850px)`, margin: "85px 0" }} />
    </>
  )
}

const Subtitle = ({ children }) => {
  const Container = ({ children, addClass = "", addStyle = {} }) => (
    <div className={`title is-size-6-mobile is-size-4 is-size-3-desktop is-size-2-fullhd has-text-weight-bold has-text-primary ${addClass}`} style={{ letterSpacing: "0.1em", ...addStyle }}>
      {children}
    </div>
  )

  return (
    <>
      {/* for Mobile */}
      <Container addClass={`${MOBILE_CLASS} has-text-centered mb-4`} >{children}</Container>
      {/* for Tablet */}
      <Container addClass={TABLET_CLASS}>{children}</Container>
      {/* for Desktop */}
      <Container addClass={DESKTOP_CLASS}>{children}</Container>
      {/* for Widescreen */}
      <Container addClass={WIDESCREEN_CLASS}>{children}</Container>
      {/* for FullHD */}
      <Container addClass={FULLHD_CLASS}>{children}</Container>
    </>
  )
}

const Hero = () => {
  return (
    <section className="hero is-medium has-background-white is-relative">
      <a href="../" alt="natadeCOCOロゴ" style={{ position: "absolute" }}>
        <Logo />
      </a>

      <div className="hero-body p-0">
        <BodyContainer>
          <Copy>
            <span className="is-inline-block" >natadeCOCOが贈る</span>
            <span className="is-inline-block" >運搬DXソリューション</span>
          </Copy>

          <div className="is-flex-mobile is-justify-content-center">
            <div className="is-flex is-flex-direction-column is-align-items-center" style={{ width: "fit-content" }}>
              <ServiceLogo />
              <Subtitle>
                <span className="is-hidden-mobile" >〜</span>
                <span className="is-inline-block" >構内での運搬は</span>
                <span className="is-inline-block" >ボクにおまかせ！</span>
                <span className="is-hidden-mobile" >〜</span>
              </Subtitle>
            </div>
          </div>
        </BodyContainer>
      </div>
    </section>
  )
}

export default Hero