import React from "react"
import Title from "./title"

import image1 from "src/images/otodokemon_scene_1.png"
import image2 from "src/images/otodokemon_scene_2.png"
import image3 from "src/images/otodokemon_scene_3.png"
import image4 from "src/images/otodokemon_scene_4.png"
import imageBgd from "src/images/otodokemon_scene_bgd.png"

const LETTER_SPACING_SIZE = "0.02em";

const Item = ({ children, imageSrc, alt }) => (
  <div className="column">
    <div className="box px-2 is-shadowless is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between" style={{ borderRadius: "1rem", height: "100%" }}>
      <p className="has-text-weight-bold has-text-centered is-size-7-mobile mb-4" style={{ letterSpacing: LETTER_SPACING_SIZE }}>{children}</p>
      <img src={imageSrc} alt={alt} style={{ minHeight: "84px", maxHeight: "250px", width: "auto", objectFit: "contain" }} />
    </div>
  </div>
)

const Scene = () => {
  return (
    <section className="section is-medium" style={{ backgroundImage: `url(${imageBgd})`, backgroundSize: "cover", backgroundPosition: "left" }}>
      <Title>
        <span className="is-inline-block" >こんなシーンで</span>
        <span className="is-inline-block" >活躍します</span>
      </Title>

      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns is-mobile" style={{ height: "100%" }}>
              <Item imageSrc={image1}>少人数のスタッフ</Item>
              <Item imageSrc={image2}>扱う荷物が頻繁に変わる</Item>
            </div>
          </div>
          <div className="column">
            <div className="columns is-mobile" style={{ height: "100%" }}>
              <Item imageSrc={image3}>コンパクトな工場</Item>
              <Item imageSrc={image4}>オフィスでも</Item>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Scene