import React from "react"
import Title from "./title"

import bgd from "src/images/otodokemon_event_bgd.png"

const LETTER_SPACING_SIZE = "0.05rem";

const Item = ({ children }) => (
  <div className="box is-shadowless" style={{ borderRadius: "2rem" }}>
    <p className="has-text-centered has-text-weight-bold is-size-7-mobile" style={{ letterSpacing: LETTER_SPACING_SIZE }}>{children}</p>
  </div>
)

const Event = () => {
  return (
    <section className="section is-medium has-background-success" style={{ backgroundImage: `url(${bgd})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <Title>
        <span className="is-inline-block" >展示会・</span>
        <span className="is-inline-block" >イベント出展</span>
      </Title>

      <div className="container">
        <Item>大阪産業局主催「センサ/DX技術展  2021」に出展しました (2021/11/19)</Item>
      </div>
    </section>
  )
}

export default Event