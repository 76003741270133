import React from "react"

import staff1 from "src/images/otodokemon_feature_staff_1.png"
import staff2 from "src/images/otodokemon_feature_staff_2.png"
import otodokemon from "src/images/otodokemon_upper_body.png"
import kirakira from "src/images/otodokemon_kirakira.svg"
import arrow from "src/images/otodokemon_arrow_down.svg"
import featureImage1 from "src/images/otodokemon_feature_1.png"
import featureImage2 from "src/images/otodokemon_feature_2.png"
import featureImage3 from "src/images/otodokemon_feature_3.png"
import bgd from "src/images/otodokemon_feature_bgd.svg"

const PALE_BLUE = "#E6F8FA"
const PALE_GRAY = "#E5EAEA"
const LETTER_SPACING_SIZE = "0.1em";

const Title = () => {
  const TitleComponent = () => (
    <h2 className="title is-size-4-mobile is-size-3 is-size-2-desktop has-text-white has-text-centered" style={{ letterSpacing: LETTER_SPACING_SIZE }}>
      <span className="is-inline-block" >おトドけもんの</span>
      <span className="is-inline-block" >ココがすごい！</span>
    </h2>
  )

  const Otodokemon = ({ addClass = "", addStyle = {} }) => <img className={addClass} src={otodokemon} alt="おトドけもん" style={{ height: "auto", ...addStyle }} />
  const OTODOKEMON_TABLET_MARGIN = "-120px"
  const OTODOKEMON_DESKTOP_MARGIN = "-160px"

  const Kirakira = ({ addClass = "", addStyle = {} }) => <img className={addClass} src={kirakira} alt="きらきら" style={addStyle} />
  const KIRAKIRA_TABLET_MARGIN = "-75px"
  const KIRAKIRA_DESKTOP_MARGIN = "-80px"


  return (
    <>
      {/* for Mobile */}
      <div className="is-hidden-tablet mx-auto">
        <TitleComponent />
        <div className="is-flex is-justify-content-center">
          <Otodokemon addClass="mr-4" addStyle={{ width: "100px", objectFit: "contain", maxHeight: "100px" }} />
          <Kirakira addClass="mb-6" addStyle={{ width: "40px" }} />
        </div>
      </div>
      {/* for Tablet */}
      <div className="is-hidden-mobile is-hidden-desktop mx-auto pb-4 is-relative" style={{ width: "fit-content", maxWidth: `calc(100% + ${OTODOKEMON_TABLET_MARGIN} + ${KIRAKIRA_TABLET_MARGIN})` }}>
        <TitleComponent />
        <Otodokemon addStyle={{ width: "100px", position: "absolute", bottom: "0", left: OTODOKEMON_TABLET_MARGIN }} />
        <Kirakira addStyle={{ width: "40px", position: "absolute", top: "-15px", right: KIRAKIRA_TABLET_MARGIN }} />
      </div>
      {/* for Desktop */}
      <div className="is-hidden-touch mx-auto pb-5 is-relative" style={{ width: "fit-content", marginTop: "-3rem", maxWidth: `calc(100% + ${OTODOKEMON_DESKTOP_MARGIN} + ${KIRAKIRA_DESKTOP_MARGIN})` }}>
        <TitleComponent />
        <div style={{ width: "1rem", height: "3rem" }} />
        <Otodokemon addStyle={{ width: "140px", position: "absolute", bottom: "0", left: OTODOKEMON_DESKTOP_MARGIN }} />
        <Kirakira addStyle={{ width: "60px", position: "absolute", top: "-15px", right: KIRAKIRA_DESKTOP_MARGIN }} />
      </div>
    </>
  )
}

const Box = ({ title, children }) => {
  const ContainerBox = ({ children, addClass = "" }) => <div className={`box is-shadowless ${addClass}`} style={{ borderRadius: "2rem", marginBottom: "75px" }}>{children}</div>

  const TitleComponent = ({ addClass = "" }) => <h3 className={`subtitle is-size-5-mobile is-size-3 is-size-2-desktop has-text-weight-bold has-text-primary has-text-centered pb-4 ${addClass}`} style={{ letterSpacing: LETTER_SPACING_SIZE, borderBottom: `solid 5px ${PALE_BLUE}` }}>{title}</h3>

  return (
    <>
      {/* for Mobile */}
      <ContainerBox addClass="is-hidden-tablet py-6">
        <TitleComponent addClass="pb-5 mb-5" />
        {children}
      </ContainerBox>
      {/* for Tablet ~ */}
      <ContainerBox addClass="is-hidden-mobile p-6">
        <TitleComponent addClass="mb-6" />
        {children}
      </ContainerBox>
    </>
  )
}

const Task = ({ children, image, alt, isReverced = false }) => {
  const ContainerBox = ({ children, addClass = "" }) => <div className={`box is-shadowless ${addClass}`} style={{ backgroundColor: PALE_GRAY, borderRadius: "1rem" }}>{children}</div>

  const Text = ({ children, addStyle = {} }) => <p className="is-size-7-mobile is-size-5-widescreen has-text-weight-bold" style={{ letterSpacing: LETTER_SPACING_SIZE, ...addStyle }}>{children}</p>

  const Image = () => (
    <div className="column is-flex is-align-items-flex-end pb-0">
      <img src={image} alt={alt} style={{ width: "100%", height: "auto", objectFit: "cover", maxWidth: "600px", marginTop: "-2rem" }} />
    </div>
  )

  return (
    <>
      {/* for Mobile */}
      <ContainerBox addClass="is-hidden-tablet px-0">
        <div className="columns">
          <div className="column is-flex is-align-items-flex-end is-justify-content-center px-0">
            <img src={image} alt={alt} style={{ width: "100%", height: "auto", maxWidth: "350px", marginTop: "-2rem", marginLeft: isReverced ? "-1.5rem" : "0", marginRight: isReverced ? "0" : "-1.5rem" }} />
          </div>
          <div className="column is-flex is-align-items-center is-justify-content-center">
            <div style={{ width: "content-fit" }}>
              <Text addStyle={{ lineHeight: "3em" }}>{children}</Text>
            </div>
          </div>
        </div>
      </ContainerBox>
      {/* for Tablet ~ */}
      <ContainerBox addClass={`is-hidden-mobile py-0 ${isReverced ? "pr-0" : "pl-0"}`}>
        <div className="columns">
          {!isReverced && <Image />}
          <div className="column is-flex is-align-items-center is-justify-content-center">
            <div className="" style={{ width: "content-fit", marginLeft: isReverced ? "0" : "-1rem", marginRight: isReverced ? "-1rem" : "0" }}>
              <Text addStyle={{ lineHeight: "4em" }}>{children}</Text>
            </div>
          </div>
          {isReverced && <Image />}
        </div>
      </ContainerBox>
    </>
  )
}

const DesktopSpacer = ({ height }) => <div className="is-hidden-mobile" style={{ width: "1rem", height }} />

const Arrow = () => (
  <>
    <DesktopSpacer height="2rem" />
    <div className="columns is-mobile is-centered">
      <div className="column is-4 my-0">
        <img className="mx-auto is-block" src={arrow} alt="↓" style={{ width: "auto" }} />
      </div>
    </div>
  </>
)

const SolutionText = ({ children }) => <p className="has-text-weight-bold has-text-centered is-size-7-mobile" style={{ letterSpacing: LETTER_SPACING_SIZE, lineHeight: "2em" }}>{children}</p>

const FeatureImage = ({ src, alt }) => (
  <>
    {/* for mobile */}
    <div className="is-hidden-tablet mt-6">
      <img src={src} alt={alt} style={{ width: "100%", maxWidth: "500px" }} />
    </div>
    {/* for tablet~ */}
    <div className="is-hidden-mobile columns is-vcentered mt-2">
      <div className="column is-one-fifths">
        <img src={bgd} alt="背景(左)" style={{ objectFit: "cover" }} />
      </div>
      <div className="is-hidden-mobile column is-three-fifths">
        <img src={src} alt={alt} style={{ objectFit: "cover" }} />
      </div>
      <div className="is-hidden-mobile column is-one-fifths">
        <img src={bgd} alt="背景(右)" style={{ objectFit: "cover", transform: "rotate(180deg" }} />
      </div>
    </div>
  </>
)

const Feature = () => {
  return (
    <section className="section is-medium pb-5">
      <div className="container">
        <Title />

        {/* 運搬作業のココが大変 */}
        <Box title={
          <>
            <span className="is-inline-block" >
              <span className="is-inline-block" >AIカメラが</span>
              <span className="is-inline-block" >荷物を判別、</span>
            </span>
            <span className="is-inline-block" >
              <span className="is-inline-block" >適切な場所へ</span>
              <span className="is-inline-block" >お届けします！</span>
            </span>
          </>
        }>
          <Task image={staff1} alt="興味を示す作業スタッフ">
            ・人が何度もモノを運ぶのは大変<br />
            ・モノの運び先がわからなくなる<br />
            ・ヒトが運搬に割く時間を減らしたい
          </Task>
          <Arrow />
          <SolutionText>
            <span className="is-inline-block" >
              <span className="is-inline-block" >おトドけもんなら</span>
              <span className="is-inline-block" >荷物を置くだけで</span>
            </span>
            <span className="is-inline-block" >
              <span className="is-inline-block" >届け先を判別、</span>
              <span className="is-inline-block" >お運びします！</span>
            </span>
          </SolutionText>
          <FeatureImage src={featureImage1} alt="荷物の内容から届け先を判別するイメージ" />
        </Box>

        {/* 運搬管理のココが大変 */}
        <Box title={
          <>
            <span className="is-inline-block" >運搬の記録を</span>
            <span className="is-inline-block" >
              <span className="is-inline-block" >デジタルデータで</span>
              <span className="is-inline-block" >自動保存！</span>
            </span>
          </>
        }>
          <Task image={staff2} alt="興味を示す管理スタッフ" isReverced>
            ・記録のデジタル化が必要<br />
            &emsp;・・・でも面倒！<br />
            ・手書きの配送伝票の取り扱いが煩雑
          </Task>
          <Arrow />
          <SolutionText>
            おトドけもんなら荷物や宛先などの情報をデジタルデータとして蓄積できます。<br />
            ボディのQRコードを読み取ると、その場でも情報を確認できます。
          </SolutionText>
          <FeatureImage src={featureImage2} alt="配送記録を蓄積しているイメージ" />
        </Box>

        <Box title={
          <>
            <span className="is-inline-block" >現場に合わせた機能の</span>
            <span className="is-inline-block" >カスタマイズが可能！</span>
          </>
        }>
          <SolutionText>
            取り扱う荷物の種類や作業フローは現場によってさまざま。<br />
            おトドけもんならAIカメラ部分や機能をカスタマイズして提供することが可能です。
          </SolutionText>
          <FeatureImage src={featureImage3} alt="機能をカスタマイズするイメージ" />
        </Box>
      </div>
    </section>
  )
}

export default Feature