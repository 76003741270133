import React from "react"
import Style from "./contact.module.scss"

import otodokemon from "src/images/otodokemon_upper_body.png"
import staff from "src/images/otodokemon_contact_image.png"
import operator from "src/images/otodokemon_contact_operator.png"
// import fukidashiColor from "src/images/otodokemon_contact_fukidashi_color.svg"
import fukidashippo from "src/images/otodokemon_contact_fukidashippo.svg"

const PALE_YELLOW = "#FFF1CB"
const DARK_BLUE = "#144895";
const LETTER_SPACING_SIZE = "0.1em";

const Item = ({ children, iconClass, action, addStyle = {} }) => {
  return (
    <div className="column" >
      <a href={action} className={`box is-shadowless px-5 pt-4 mb-5 ${Style.item}`} style={{ height: "100%", color: DARK_BLUE, border: `solid 4px ${DARK_BLUE}`, borderRadius: "1rem" }}>
        <div className="is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
          <div className="is-hidden-mobile" style={{ width: "1rem", height: "1rem" }} />
          <span className="icon is-large mb-2">
            <i className={`is-hidden-tablet ${iconClass} fa-2x`} />
            <i className={`is-hidden-mobile ${iconClass}`} style={{ fontSize: "45px" }} />
          </span>
          <div className="is-hidden-mobile" style={{ width: "1rem", height: "1rem" }} />
          <p className="has-text-weight-bold has-text-centered my-auto is-size-5-mobile is-size-4 is-size-3-desktop" style={{ letterSpacing: LETTER_SPACING_SIZE, ...addStyle }}>
            {children}
          </p>
        </div>
      </a>
    </div>
  )
}

const AbsoluteImage = ({ src, isLeft = false, isRight = false }) => {
  const MOBILE_WIDTH = "120px"
  const TABLET_WIDTH = "160px"
  const DESKTOP_WIDTH = "250px"

  const WRAPPER_COMMON_PROP = { position: "absolute" }
  const IMAGE_COMMON_PROP = { src, alt: "お客様からのお問い合わせを歓迎するイラスト" }

  if (isLeft) {
    return (
      <>
        <div style={{ ...WRAPPER_COMMON_PROP, top: "-120px", left: "0", zIndex: "-1" }}>
          <img className="is-hidden-tablet" {...IMAGE_COMMON_PROP} style={{ width: MOBILE_WIDTH }} />
        </div>
        <div style={{ ...WRAPPER_COMMON_PROP, bottom: "-3rem", left: `calc(${TABLET_WIDTH} / 2 * -1 * 0.25)` }}>
          <img className="is-hidden-mobile is-hidden-desktop" {...IMAGE_COMMON_PROP} style={{ width: TABLET_WIDTH }} />
        </div>
        <div style={{ ...WRAPPER_COMMON_PROP, bottom: "-3rem", left: `calc(${DESKTOP_WIDTH} / 2 * -1)` }}>
          <img className="is-hidden-touch" {...IMAGE_COMMON_PROP} style={{ width: DESKTOP_WIDTH }} />
        </div>
      </>
    )
  }
  if (isRight) {
    return (
      <>
        <div style={{ ...WRAPPER_COMMON_PROP, top: "-120px", right: "0", zIndex: "-1" }}>
          <img className="is-hidden-tablet" {...IMAGE_COMMON_PROP} style={{ width: MOBILE_WIDTH }} />
        </div>
        <div style={{ ...WRAPPER_COMMON_PROP, bottom: "-3rem", right: `calc(${TABLET_WIDTH} / 2 * -1 * 0.25)` }}>
          <img className="is-hidden-mobile is-hidden-desktop" {...IMAGE_COMMON_PROP} style={{ width: TABLET_WIDTH }} />
        </div>
        <div style={{ ...WRAPPER_COMMON_PROP, bottom: "-3rem", right: `calc(${DESKTOP_WIDTH} / 2 * -1)` }}>
          <img className="is-hidden-touch" {...IMAGE_COMMON_PROP} style={{ width: DESKTOP_WIDTH }} />
        </div>
      </>
    )
  }
}

const FukidashiForUser = ({ children }) => {
  const Frame = ({ children, addClass, addStyle }) => <div className={`has-background-white py-5 is-relative ${addClass}`} style={{ borderRadius: "1rem", border: "solid 4px #2a2a2a", ...addStyle }}>{children}</div>
  const Shippo = ({ addStyle }) => <img src={fukidashippo} alt="ふきだし" style={{ width: "28px", height: "25px", position: "absolute", ...addStyle }} />

  return (
    <>
      <Frame addClass="is-hidden-tablet px-5 mt-5 mx-auto" addStyle={{ width: "512px", maxWidth: "100%", height: "auto" }}>
        {children}
        <Shippo addStyle={{ bottom: "-23px", right: "2rem", transform: "rotate(-90deg)" }} />
      </Frame>
      <Frame addClass="is-hidden-mobile px-6" addStyle={{ width: "100%", height: "auto" }}>
        {children}
        <Shippo addStyle={{ top: "50%", left: "calc(-28px + 3px)" }} />
      </Frame>
    </>
  )
}

const Contact = () => {
  return (
    <>
      <div className="is-flex-tablet is-flex-direction-row-reverse is-align-items-flex-end is-justify-content-center pt-4 is-relative" style={{ backgroundColor: PALE_YELLOW }}>
        <div className="" style={{ position: "absolute", bottom: "-100px", left: "calc(50% - 100px / 2)", width: "100px", height: "80px", border: "solid 50px transparent", borderTopColor: PALE_YELLOW }} />
        <div className="is-flex is-flex-direction-column mx-5">
          <div className="is-hidden-mobile" style={{ width: "1rem", height: "5rem" }} />
          <FukidashiForUser>
            <p className="is-size-4 is-size-5-mobile has-text-weight-bold has-text-centered is-block" style={{ letterSpacing: LETTER_SPACING_SIZE }}>
              <span className="is-inline-block">ウチにも</span>
              <span className="is-inline-block">導入できるかな？</span>
            </p>
          </FukidashiForUser>
          <p className="is-size-6-mobile is-size-5 is-size-4-desktop has-text-weight-bold mt-5 has-text-centered-mobile has-text-right-tablet" style={{ letterSpacing: LETTER_SPACING_SIZE }}>
            ・・・と気になったら
          </p>
          <div className="is-hidden-mobile" style={{ width: "1rem", height: "5rem" }} />
        </div>
        <div className="is-flex is-justify-content-center is-align-self-flex-end">
          <img className="is-hidden-tablet" src={staff} alt="興味を示すスタッフ" style={{ width: "75%", maxWidth: "450px", height: "100%" }} />
          <img className="is-hidden-mobile" src={staff} alt="興味を示すスタッフ" style={{ width: "450px", height: "100%", objectFit: "contain" }} />
        </div>
      </div>

      <section className="section has-background-white pb-0" >
        <div className="is-hidden-mobile" style={{ width: "1rem", height: "1.5rem" }} />
        <h2 className="title is-size-4-mobile is-size-3 is-size-2-desktop has-text-centered my-6" style={{ letterSpacing: LETTER_SPACING_SIZE, paddingTop: "1.5rem" }}>
          <span className="is-inline-block">お問い合わせは</span>
          <span className="is-inline-block">こちらまで</span>
        </h2>
        <div className="is-hidden-mobile" style={{ width: "1rem", height: "1.5rem" }} />

        <div className="container pt-5 pb-3" >
          <div className="columns">
            <Item action="tel:0647076004" iconClass="fas fa-headset" addStyle={{ letterSpacing: "0.2em" }}>
              <span className="is-inline-block">06-4707-6004</span><br />
              <span className="is-inline-block is-size-7" style={{ letterSpacing: LETTER_SPACING_SIZE }}>(平日10:00-19:00)</span>
            </Item>
            <Item action="../contact/inquery" iconClass="far fa-paper-plane" >
              <span className="is-inline-block">お問い合わせフォーム</span><br />
              <span className="is-inline-block is-size-7" style={{ letterSpacing: LETTER_SPACING_SIZE }}>お問い合わせページへ移動します</span>
            </Item>
          </div>
        </div>

        <div className="is-hidden-tablet-only" style={{ width: "100%", height: "4rem" }} />

        <div className="container pb-6" style={{ marginTop: "6rem", zIndex: "1" }} >
          <div className="py-5 mx-auto is-relative" style={{ backgroundColor: DARK_BLUE, width: "100%", maxWidth: "720px", borderRadius: "0.5rem" }}>
            <AbsoluteImage src={operator} isLeft />
            <div className="is-hidden-touch" style={{ width: "1rem", height: "1.5rem" }} />
            <p className="is-size-7-mobile is-size-6 is-size-5-desktop has-text-weight-bold has-text-white has-text-centered my-auto" style={{ letterSpacing: LETTER_SPACING_SIZE, lineHeight: "1.75em" }}>
              <span className="is-inline-block" >最適な導入プランを</span>
              <span className="is-inline-block" >ご提案いたします</span><br />
              <span className="is-inline-block" >まずはお気軽に</span>
              <span className="is-inline-block" >お問い合わせください</span>
            </p>
            <div className="is-hidden-touch" style={{ width: "1rem", height: "1.5rem" }} />
            <AbsoluteImage src={otodokemon} isRight />
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact