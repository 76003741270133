import React from "react"
import Title from "./title"
import Style from "./concept.module.scss"

import bgd from "src/images/otodokemon_concept_bgd.png"
import goImage from "src/images/otodokemon_concept_otodokemon_go.png"
import backImage from "src/images/otodokemon_concept_otodokemon_back.png"

const LETTER_SPACING_SIZE = "0.1em";

const Otodokemon = ({ src, addClass }) => <img className={addClass} src={src} alt="おトドけもんが荷物を運ぶイメージ" style={{ position: "absolute", bottom: "0", height: "262px" }} />

const Concept = () => {
  return (
    <section className="section is-medium">
      <div className="container">
        <Title isLeft>
          <span className="is-inline-block" >製造現場に</span>
          <span className="is-inline-block" >ぴったりな相棒！</span>
        </Title>

        <div className="columns is-vcentered">
          <div className="column">
            <p className="has-text-weight-bold has-text-white is-size-7-mobile has-text-centered-mobile" style={{ lineHeight: "4em", letterSpacing: LETTER_SPACING_SIZE, marginLeft: "0px" }}>
              <span className="is-inline-block" >
                <span className="is-inline-block" >おトドけもんは、構内の運搬における</span>
                <span className="is-inline-block" >DXソリューションです。</span>
              </span>
              <span className="is-inline-block" >
                <span className="is-inline-block" >100サイズ荷物の運搬能力があるロボットに</span>
                <span className="is-inline-block" >拡張キットを搭載することで、</span>
              </span>
              <span className="is-inline-block" >
                <span className="is-inline-block" >繰り返し人流が発生する運搬作業を</span>
                <span className="is-inline-block" >スマートにデジタル化します。</span>
              </span>
            </p>
          </div>

          <div className="column is-flex is-align-items-center is-relative" style={{ backgroundImage: `url(${bgd})`, backgroundSize: "auto 362px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat", minHeight: "360px", overflow: "hidden" }} >
            <Otodokemon addClass={Style.go} src={goImage} />
            <Otodokemon addClass={Style.back} src={backImage} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Concept